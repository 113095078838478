:root {
  // Primary Palette
  --PP_Yellow: #f6be00;
  --PP_DarkGray: #3f4444;
  --PP_LightGray: #f2f2f2;
  ////////////////////

  // Neutral Progression
  --Gray7: #292c2c;
  --Gray6: #636767;
  --Gray5: #909494;
  --Gray4: #bcbfbf;
  --Gray3: #d2d4d4;
  --Gray2: #e9eaea;
  //////////////////////

  //Blue Progression
  --DarkBlue: #0044c7;
  --Blue: #1b60e5;
  --PaleBlue: #a6c5ff;
  --PalestBlue: #e9f1ff;
  //////////////////

  // Type Details
  --H_Mini_SubTitle_Weight: 600;
  --H1_Size: 24px;
  --H2_Size: 18px;
  --H3_Size: 16px;
  --Body1_SubTitle_Size: 14px;
  --Body2_Size: 12px;
  --Mini_Size: 10px;
  --H1_Body2_LetterSpacing: 0.016px;
  --H2_H3_Body1_SubTitle_LetterSpacing: 0.009px;
  --Mini_LetterSpacing: 0.03px;
  --H1_LineSpacing: 2.25;
  --H2_LineSpacing: 1.6875;
  --H3_LineSpacing: 1.5;
  --Body1_SubTitle_LineSpacing: 1.3125;
  --Body2_LineSpacing: 1.125;
  --Mini_LineSpacing: 0.9375;

  --menuWidthMobileExpanded: 100vw; //collpased
  --menuWidthMobileCollapsed: 56px; //expanded
  --menuWidthDesktopCollapsed: 56px;
  --menuWidthDesktopExpanded: 240px;

  --MenuExpanded: 240px;

  --MenuCollapsed: 56px;
}
